<template>
  <div class="row px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          General
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="card p-60px">
          <div class="row">
            <div class="col-12 col-sm-12">
              <b-tabs class="settings-tabs">
                <b-tab active>
                  <template #title>
                    Customer Information
                  </template>
                  <div class="row" v-if="loading == true">
                    <div class="col-12 col-sm-12">
                      <grid-loader
                        class="expertel-loader"
                        color="#ff502d"
                        :loading="loading"
                        :size="20"
                        sizeUnit="px"
                      ></grid-loader>
                    </div>
                  </div>
                  <div class="row" v-if="loading == false">
                    <div class="col-12 col-sm-12 col-xl-5 pl-0">
                      <div class="mt-50px settings-form">
                        <div class="input-container">
                          <label for="">Name</label>
                          <input
                            type="text"
                            class="form-control"
                            :value="`${user_info.name}`"
                          />
                        </div>
                        <div class="input-container">
                          <label for="">Contact Name</label>
                          <input
                            type="text"
                            class="form-control"
                            :value="`${user_info.contact_name}`"
                          />
                        </div>
                        <div class="input-container">
                          <label for="">Email</label>
                          <input
                            type="text"
                            class="form-control"
                            :value="`${user_info.email}`"
                          />
                        </div>
                        <div class="input-container">
                          <label for="">Phone</label>
                          <input
                            type="text"
                            class="form-control"
                            :value="`${user_info.phone}`"
                          />
                        </div>
                        <div class="input-container">
                          <label for="">Report Notifications</label>
                          <select
                            class="form-control"
                            v-model="user_info.send_report_notifications"
                            disabled
                          >
                            <option value="0">Disabled</option>
                            <option value="1">Enabled</option>
                          </select>
                        </div>
                        <button
                          @click.prevent="editData()"
                          class="button-expertel float-right"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab lazy>
                  <template #title>
                    Location Details
                  </template>
                  <div class="row" v-if="loading == false">
                    <div class="col-12 col-sm-12 col-xl-5 pl-0">
                      <div class="mt-50px settings-form">
                        <div class="input-container">
                          <label for="">Address</label>
                          <input
                            type="text"
                            class="form-control"
                            :value="`${user_info.address}`"
                          />
                        </div>
                        <div class="input-container">
                          <label for="">City</label>
                          <input
                            type="text"
                            class="form-control"
                            :value="`${user_info.city}`"
                          />
                        </div>
                        <div class="input-container">
                          <label for="">Province</label>
                          <input
                            type="text"
                            class="form-control"
                            :value="`${user_info.province}`"
                          />
                        </div>
                        <div class="input-container">
                          <label for="">Postal Code</label>
                          <input
                            type="text"
                            class="form-control"
                            :value="`${user_info.postal_code}`"
                          />
                        </div>
                        <button
                          @click.prevent="editData()"
                          class="button-expertel float-right"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      user_info: [],
      loading: true
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getData();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    getData() {
      const self = this;
      this.loading = true;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/info`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.user_info = response.data;
          self.loading = false;
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    editData() {
      const self = this;
      this.loading = true;
      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        },
        data: self.user_info
      })
        .then(function(response) {
          self.user_info = response.data;
          self.loading = false;
          self.makeToast("Success", "Customer Info Updated", "success");
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    }
  }
};
</script>
